.App {
  text-align: center;
  background-color: #000000;
  padding: 1em;
}

* {
  font-family: 'Press Start 2P';
  image-rendering: pixelated;
}

h2 {
  font-size: 0.75em !important;
  padding: 8px;
}


h1 {
  font-size: 1.5em !important;
  padding: 8px;
}

p {
  font-size: 0.75em !important;
  line-height: 1.5em;
}

a {
  color: white !important;
  font-size: 0.825em !important;
}

html {
  background-color: #000000;
}
/* App.css updates */

.image-container {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  padding: 10px;
  gap: 10px;
}

@media (min-width: 768px) {
  .line-break {
    flex-basis: 100%;
    height: 0;
  }
}



@font-face {
  font-family: 'Press Start 2P';
  src: url('PressStart2P-Regular.ttf') format('truetype');
}

.image-item {
  width: 65px; /* Set width to 65px */
  height: 65px; /* Set height to 65px */
  object-fit: cover; /* This will ensure the images cover the area without losing their aspect ratio */
  cursor: pointer;
}

/* Adjusts the modal content size */
.react-modal-content {
  max-width: 800px;
  width: 90%;
  margin: auto;
}

.my-svg {
  padding: 0.5em;
}

/* Ensure the modal covers the entire screen */
.ReactModal__Overlay {
  z-index: 1000;
}

.metadata-badge {
  margin: 16px;
  padding: 16px 32px;
  font-size: 0.6em;
  color: white;
  background-color: #21a92b;
  border-radius: 10px;
}

.metadata-container {
  padding: 20px;
  text-align: center; /* Center badges if desired */
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}


/* Media query for screens below 500px */
@media screen and (max-width: 500px) {
  .metadata-badge {
  display: inline-block;
  margin: 8px;
  padding: 16px 32px;
  font-size: 0.6em;
  color: white;
  background-color: #21a92b;
  border-radius: 10px;
}
}

